/**
 * @method 导出接口
 * @param {string} url 请求地址
 * @param {Object} data 需要传入的参数
 * @param {string} name 需要定义的文件名
 */
import { axios } from '@/utils/request';
import { Message } from 'element-ui';

export function exportMethods(url, data, name) {
  return axios({
    url,
    method: 'post',
    data,
    responseType: 'blob'
  }).then(res => {
    exportFun(res, name)
  });
};

function exportFun(res, name) {
  if (res.status !== 200) {
    Message({
      type: 'error',
      message: '文件导出失败，请稍后重试!',
      duration: 2000
    });
    return Promise.reject(res)
  }
  const blob = new Blob([res.data], { type: 'application/vnd.ms-excel' })
  let fileName = ''
  if (name) {
    fileName = name
  } else {
    let h = JSON.stringify(res.headers)
    let j = JSON.parse(h)
    console.log(j);
    fileName = j['content-disposition'] ? decodeURI(j['content-disposition'].split('attachment; filename=')[1]) :'文件'
  }
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.click()
  window.URL.revokeObjectURL(link.href)
  Message({
    type: 'success',
    message: '文件导出成功!',
    duration: 2000
  });
  return Promise.resolve(res)
}
